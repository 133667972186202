import AuthService from "../service/authService"
import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import Coursier from "../Coursier"

class AuthRouter extends Component{


    render(){
        if(AuthService.isLogin()){
            return <Coursier {...this.props} />
        }else{
            return <Redirect to="/login" />
        }
    }
}

export default AuthRouter;