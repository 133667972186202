import React,{Component} from "react";
import {Form,Button, Card, Row,Col, Container,Alert} from 'react-bootstrap';
import EnlevementAPI from "../service/EnlevementAPI";
import AuthService from "../service/authService"


class Saisi extends Component{

    constructor(props) {
        super(props);
        this.state = {
                listeTown: [],
                listeClient:[],
                listeAgence:[],
                listeAgenceExpedition:[],
                listeAgenceDestination:[],
                listeType:[],
                alertSuccess:false,
                errorForm:{
                    statuError:false,message:""
                },
                numeroRT:"",
                form_codeUser: "",
                codeUserAlert:{active:false,sms:""},
                form_client: null,
                form_villeExpedition: null,
                form_agenceExpedition: null,
                form_villeDestination: null,
                form_agenceDestination: null,
                form_nomExpediteur: "",
                form_telExpediteur: "",
                form_emailExpediteur: "",
                form_nomDestinataire: "",
                form_telDestinataire: "",
                form_adresseDestination: "",
                form_montant:0,
                form__colis_numeroRT: "",
                form__colis_natureColis: 1,
                form__colis_poidsColis: 0.9,
                form__colis_unitePoids: "kg",
                form__colis_nombre: 2,
                form__colis_valeurMarchande:0,
                form__colis_longueur: 0,
                form__colis_uniteLongueur: "cm",
                form__colis_largeur: 0,
                form__colis_uniteLargeur: "cm",
                form__colis_hauteur: 0,
                form__colis_uniteHauteur: "cm",
                form__colis_description: ""
            }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onRegionDestChange = this.onRegionDestChange.bind(this);
        this.onRegionExpedionChange = this.onRegionExpedionChange.bind(this);
        this.reinitialisation = this.reinitialisation.bind(this);
        this.isCodeError = this.isCodeError.bind(this);
    }

    isCodeError(){

    }

    onRegionExpedionChange(event){
        var id;    
        isNaN(event)?id = parseInt(event.target.value, 10):id = 1;

        //isNaN(event)?console.log("--- value target --- "+[event.target.name]+" --- value --- "+ event.target.value):console.log("is num:"+event)
        
        var liste = EnlevementAPI.filterAgencePerRegion(this.state.listeAgence,id);
        this.setState({
            listeAgenceExpedition:liste,
            //form_agenceExpedition: liste[0].id,
            form_villeExpedition: id
        });
            
    }

    onRegionDestChange(event){
        var id;    
        isNaN(event)?id = parseInt(event.target.value, 10):id = 1;

        //isNaN(event)?console.log("--- value target --- "+[event.target.name]+" --- value --- "+ event.target.value):console.log("is num:"+event)
        
        var liste = EnlevementAPI.filterAgencePerRegion(this.state.listeAgence,id);
        this.setState({
            listeAgenceDestination: liste,
            //form_agenceDestination: liste[0].id,
            form_villeDestination: id
        });
    }
    reinitialisation(){
        this.setState({
            
            validatedForm: false,
            alertSuccess:false,
            errorForm:{
                statuError:false,message:""
            },
            numeroRT:"",
            form_codeUser: "",
            form_client: null,
            form_villeExpedition: null,
            form_agenceExpedition: null,
            form_villeDestination: null,
            form_agenceDestination: null,
            form_nomExpediteur: "",
            form_telExpediteur: "",
            form_emailExpediteur: "",
            form_nomDestinataire: "",
            form_telDestinataire: "",
            form_adresseDestination: "",
            form_montant:0,
            form__colis_numeroRT: "",
            form__colis_natureColis: null,
            form__colis_poidsColis: 0.9,
            form__colis_unitePoids: "kg",
            form__colis_nombre: 0,
            form__colis_valeurMarchande:0,
            form__colis_longueur: 0,
            form__colis_uniteLongueur: "cm",
            form__colis_largeur: 0,
            form__colis_uniteLargeur: "cm",
            form__colis_hauteur: 0,
            form__colis_uniteHauteur: "cm",
            form__colis_description: ""
        })
    }

    closeAlert(choice){
        choice==="danger"?
        this.setState({
            errorForm:{
                statuError:false
            },
            codeUserAlert:{active:false,sms:""}
        }):
        this.setState({
            alertSuccess:false
        })
    }

    refreshForm(){  

        EnlevementAPI.getTypeColis().then(
            resp => {
                console.log("-- en cour --");
                if(resp.data.code ){
                    AuthService.onLogout();
                }else{
                    if(resp.status === 200){
                        this.setState({
                            listeType : resp.data.data.entities
                        });
                    }
                }
            }
        ).catch((error)=>{
            AuthService.onLogout();
            this.props.history.push("/login");
        }).then(()=>{
            
            EnlevementAPI.getAllTown().then(
                resp => {
                    if(resp.data.code){
                        AuthService.onLogout();
                    }else{
                        if(resp.status === 200) this.setState({listeTown : resp.data.data.entities});
                    }
                }
            )
        }).then(()=>{
            EnlevementAPI.getAllAgence().then(
                resp => {
                    if(resp.status === 200){
                        this.setState({
                            listeAgence : resp.data.data.entities
                        });
                    }
                }
            )
        }).then(()=>{
            EnlevementAPI.getClient().then(
                resp => {
                    if(resp.status === 200){
                        this.setState({
                            listeClient : resp.data.data.entities,
                        });
                    }
                    
                }
            )
        }).catch((error)=>{
            AuthService.onLogout();
            //this.props.history.push("/login");
        })

    }
        
        
    

    handleChange (event) {
        
        this.setState({
            [event.target.name]: isNaN(event.target.value)?event.target.value:parseInt(event.target.value)
        });
    }

    componentDidMount(){
        this.setState({
            form_codeUser : sessionStorage.getItem("code"),
        });
        
        this.refreshForm();
    }

    render(){
        return(
            <div className="Saisi">
               <Container >
               <Alert key="1003" variant="danger" show={this.state.codeUserAlert.active} onClose={() => this.closeAlert("danger")} dismissible>
                    {this.state.codeUserAlert.sms}
                </Alert>
               <Alert key="1002" variant="danger" show={this.state.errorForm.statuError} onClose={() => this.closeAlert("danger")} dismissible>
                    {this.state.errorForm.message}
                </Alert>
                <Alert key="1001" variant="success" show={this.state.alertSuccess} onClose={() => this.closeAlert("success")} dismissible>
                    Côlis N&#xb0; {this.state.numeroRT} enregistré avec success!
                </Alert>
                <Card style={{ textAlign: "left", fontSize: 14 }}>
                    <Card.Header bg="dark" text="white" >Enregistrer un enlèvement</Card.Header>
                    <Card.Body>
                        <Form validated={this.state.validatedForm} onSubmit={this.handleSubmit}>
                            <Form.Row >
                                <Form.Group as={Col} controlId="product.part0" controlId="product.part1" style={{fontSize:24,fontWeight:"bold"}}>
                                    <Form.Label>Code utilisateur</Form.Label>
                                    <Form.Control style={{color:"green"}} plaintext readOnly defaultValue={this.state.form_codeUser} />
                                </Form.Group>
                            </Form.Row>
                            
                            <Form.Row>
                                <Form.Group as={Col} controlId="product.part2">
                                    <Form.Label>N&#xb0; RT</Form.Label>
                                    <Form.Control name="form__colis_numeroRT" value={this.state.form__colis_numeroRT} placeholder="No LT" onChange={this.handleChange}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="product.part3">
                                    <Form.Label>Client</Form.Label>
                                    <Form.Control required as="select" name="form_client" onChange={this.handleChange}>
                                        <option key="100" value="#">--- Choisir client ---</option>
                                        {
                                            this.state.listeClient.map((client, key) =>
                                            <option key={key} value={client.id}>{client.raisonSociale}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>   
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="place.part1">
                                    <Form.Label>Ville expédition</Form.Label>
                                    <Form.Control as="select" name="form_villeExpedition" onChange={this.onRegionExpedionChange}>
                                        <option key="100" value="#">--- Choisir ville expedition ---</option>
                                        {
                                            this.state.listeTown.map((town, key) =>
                                            <option key={town.id} value={town.id}>{town.nom}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} controlId="product.part2">
                                    <Form.Label>Ville destination</Form.Label>
                                    <Form.Control as="select" name="form_villeDestination" onChange={this.onRegionDestChange}>
                                        <option key="100" value="#">--- Choisir ville destination ---</option>
                                        {
                                            this.state.listeTown.map((town, key) =>
                                            <option key={town.id} value={town.id}>{town.nom}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Row>
                                <Form.Group as={Col} controlId="product.part3">
                                    <Form.Label>Bureau expédition</Form.Label>
                                    <Form.Control as="select" name="form_agenceExpedition" onChange={this.handleChange}>
                                        <option key="100" value="#">--- Choisir bureau expedition ---</option>
                                        {
                                            this.state.listeAgenceExpedition.map((agence, key) =>
                                            <option key={agence.id} value={agence.id}>{agence.nom}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} controlId="product.part4">
                                    <Form.Label>Bureau destination</Form.Label>
                                    <Form.Control as="select" name="form_agenceDestination" onChange={this.handleChange}>
                                        <option key="100" value="#">--- Choisir bureau destination ---</option>
                                        {
                                            this.state.listeAgenceDestination.map((agence, key) =>
                                            <option key={agence.id} value={agence.id}>{agence.nom}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="product.part5">
                                    <Form.Label>Nature Colis</Form.Label>
                                    <Form.Control as="select" name="form__colis_natureColis" onChange={this.handleChange}>
                                        <option key="100" value="#">--- Choisir type colis ---</option>
                                        {
                                            this.state.listeType.map((type, key) =>
                                            <option key={type.id} value={type.id}>{type.libelle}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} controlId="product.part6">
                                    <Form.Label>Valeur</Form.Label>
                                    <Form.Control required type="number"  name="form__colis_valeurMarchande" value={this.state.form__colis_valeurMarchande} onChange={this.handleChange} placeholder="valeur marchande" />
                                </Form.Group>
                            </Row>
                            <Form.Group as={Col} controlId="product.part7">
                                <Form.Label>Descritption</Form.Label>
                                <Form.Control required type="textarea" name="form__colis_description" value={this.state.form__colis_description} onChange={this.handleChange} placeholder="Descritption" />
                            </Form.Group>
                            <Row>
                                <Form.Group as={Col} controlId="product.part6">
                                    <Form.Label>Montant Facture</Form.Label>
                                    <Form.Control required type="number"  name="form_montant" value={this.state.form_montant} onChange={this.handleChange} placeholder="montant" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="pers.part1">
                                    <Form.Label>Nom expéditeur</Form.Label>
                                    <Form.Control required name="form_nomExpediteur" value={this.state.form_nomExpediteur} onChange={this.handleChange} placeholder="nom expéditeur" />
                                </Form.Group>
                                <Form.Group as={Col} controlId="pers.part2">
                                    <Form.Label>Nom destinataire</Form.Label>
                                    <Form.Control  required name="form_nomDestinataire" value={this.state.form_nomDestinataire} onChange={this.handleChange} placeholder="nom destinataire" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="pers.part2">
                                    <Form.Label>Tel: expéditeur</Form.Label>
                                    <Form.Control required name="form_telExpediteur" value={this.state.form_telExpediteur} onChange={this.handleChange} placeholder="Tel: " />
                                </Form.Group>
                                <Form.Group as={Col} controlId="pers.part3">
                                    <Form.Label>Tel: dest</Form.Label>
                                    <Form.Control required name="form_telDestinataire" value={this.state.form_telDestinataire} onChange={this.handleChange} placeholder="Tel: " />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group as={Col} controlId="pers.part6"> 
                                    
                                </Form.Group>
                                <Form.Group as={Col} controlId="pers.part4"> 
                                    <Button type="cancel" variant="default" onClick={this.reinitialisation} style={{
                                        boxShadow: "0px 10px 14px -7px gray",
                                        
                                        backgroundColor:"gray",
                                        borderRadius:"4px",
                                        border:"1px solid gray",
                                        display:"inline-block",
                                        cursor:"pointer",
                                        color:"white",
                                        fontFamily:"Arial",
                                        fontSize:"13px",
                                        fontWeight:"bold",
                                        padding:"7px",
                                        textDecoration:"none",
                                        textShadow:"0px 1px 0px #5b8a3c"
                                    }}>Annuler</Button>
                                </Form.Group>
                                <Form.Group as={Col} controlId="pers.part5" >
                                    <Button type="submit" style={{
                                            boxShadow: "0px 10px 14px -7px gray",
                                        
                                            backgroundColor:"green",
                                            borderRadius:"4px",
                                            border:"1px solid gray",
                                            display:"inline-block",
                                            cursor:"pointer",
                                            color:"white",
                                            fontFamily:"Arial",
                                            fontSize:"13px",
                                            fontWeight:"bold",
                                            padding:"7px",
                                            textDecoration:"none",
                                            textShadow:"0px 1px 0px #5b8a3c"
                                        }}>
                                        Enregistrer
                                    </Button>
                                </Form.Group>
                                <Form.Group as={Col} controlId="pers.part7"> 
                                    
                                </Form.Group>
                            </Row>
                            
                        </Form>
                    </Card.Body>
                </Card>
               </Container>
            </div>
        );
    }

    handleSubmit (event) {

        event.preventDefault(); 

        var dataToArray = [];
        var emptyListe = "";

        var data ={
            "codeCoursier": this.state.form_codeUser,
            "client":this.state.form_client,
            "villeExpedition":this.state.form_villeExpedition,
            "agenceExpedition":this.state.form_agenceExpedition,
            "villeDestination":this.state.form_villeDestination,
            "agenceDestination":this.state.form_agenceDestination,
            "contactExpediteur":this.state.form_nomExpediteur,
            "telExpediteur":this.state.form_telExpediteur,
            "emailExpediteur":"",
            "contactDestinataire":this.state.form_nomDestinataire,
            "telDestinataire":this.state.form_telDestinataire,
            "adresseDestination":this.state.form_adresseDestination,
            "montant":this.state.form_montant,
            "colis":[{
                "natureColis":this.state.form__colis_natureColis,
                "poidsColis":0,
                "unitePoids":"kg",
                "nombre":1,
                "valeurMarchande":this.state.form__colis_valeurMarchande,
                "longueur":0,
                "uniteLongueur":"cm",
                "largeur":0,
                "uniteLargeur":"cm",
                "hauteur": 0,
                "uniteHauteur":"cm",
                "description":this.state.form__colis_description
            }]
        };
        console.log(data)

        dataToArray = Object.entries(data);

        dataToArray.forEach(element => {
            if(element[1] == null){
                emptyListe = emptyListe+" "+element[0];
            }
        });

        if(emptyListe.length === 0){
            EnlevementAPI.postEnlevement(data).then(
            resp =>{
                console.log(resp.data.data.entity);
                this.reinitialisation();
                this.setState({
                    alertSuccess:true,
                    numeroRT: resp.data.data.entity.numLettreTransport,
                    errorForm:{statuError:false,message:""}
                })
            })
        }else{
            this.setState({
                alertSuccess:false,
                errorForm:{statuError:true,message:"Merci de renseigner: "+emptyListe}
            })
        }

        
        
    }
}

export default Saisi