
import React, {Component} from 'react';
import {isMobile} from "react-device-detect";
import { Row,Col,Form, Card, Accordion, Container, Badge, Alert,Button } from "react-bootstrap";
import EnlevementAPI from "../service/EnlevementAPI";
import {startDate,endDate} from "../config";
import Moment from 'react-moment';
import AuthService from '../service/authService';


class ListLivraisons extends Component {

    constructor(props){
        super(props);
        this.state={
            resolt:[],
            date1: "",
            date2: "",
            filter:"",
            codeUser: "",//CR001
            filterResolt:[],
            errorMessage:{
                alertSuccess:{active:false,sms:""}
            }
        }

        this.refreshList= this.refreshList.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.onValideRefresh = this.onValideRefresh.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    convertDate(date){
        var tableDate = date.split("-");
        return tableDate[2]+"/"+tableDate[1]+"/"+tableDate[0];
    }

    closeAlert(choice){
        this.setState({
            errorMessage:{
                alertSuccess:{active:false,sms:""}
            }
        })
    }

    refreshList(){
        var cdate1 = "";
        var cdate2 = "";

        if(this.state.date1 === "" ){
            cdate1 = startDate;
            cdate2 = endDate;
        }else{
            cdate1 = this.convertDate(this.state.date1);
            cdate2 = this.convertDate(this.state.date2);
        }


        console.log("+++++++++++ code +++++++++++++ "+this.state.codeUser);
        EnlevementAPI.getAllExpedition(this.state.codeUser, cdate1,cdate2).then(
            resp => {
                if(resp.data.status === "success"){
                    //console.log("+++++++++++ resp.data.data +++++++++++++ ");
                    //console.log(resp.data.data);
                    this.setState({
                        resolt:resp.data.data.entities,
                        filterResolt: resp.data.data.entities
                    })
                    this.setState({
                        errorMessage:{
                            alertSuccess:{active:true,sms: resp.data.data.entities.length+" expédition(s) éffectué(s) entre le "+cdate1+" et le "+cdate2}
                        }
                    })
                }
            }
        ).catch((error) => { 
            var final = error+"-";
                console.log("-------------------");
                console.log(final.includes("401"));
                console.log("-------------------");
                if(final.includes("401")){
                    AuthService.onLogout();
                    this.props.history.push("/login");
                }
        })
    }

    onValideRefresh(event){
        this.setState({
            errorMessage:{
                alertSuccess:{active:false,sms:""},
                codeUser: "CR001",
            }
        })
        this.refreshList();
        event.preventDefault();
    }

    

    onFilter(event){
        
        if(event.target.value===""){
            this.setState({
                filter: "",
                filterResolt: this.state.resolt
            })
        }else{
            //console.log("------------ item -----------");
            //console.log(this.state.resolt);
            var filterResot = this.state.resolt.filter(function(item){
                
                var filterStep1 = Object.values(item);
                var temp = [];
                //console.log(filterStep1);
                filterStep1.forEach(element => {
                    if(!Array.isArray(element)){
                        if(typeof element === 'object' && element != null){
                            //console.log("------------ object --------------");
                            //console.log(Object.values(element));
                            temp = temp.concat(Object.values(element))
                            //console.log(temp);
                        }else{
                            temp.push(element)
                        }
                    }else{
                        element.forEach(emt => {
                            if(typeof emt === 'object'){
                                temp.concat(Object.values(emt))
                            }else{
                                temp.push(temp)
                            }
                        })
                    }
                    
                });
                ///console.log("------------ filter --------");
                //console.log(temp);

                return temp.join().toLowerCase().includes(event.target.value) ;
            });
            
            this.setState({
                filter: event.target.value,
                filterResolt: filterResot
            })
        }
        
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    componentDidMount(){
        this.setState({
            codeUser: sessionStorage.getItem("code")
        })
    }


    render(){

        const styles = {
            styleMobile: {
                marginLeft: 1,
                marginRigth: 1
            },
            styleDesktop: {
                marginLeft: 500,
                marginRight: 500
            }

        }

        return(
            <div className="ListeSaisi">
                <Container fluid="xs" style={isMobile?styles.styleMobile:styles.styleDesktop}>
                    <Alert key="1001" variant="success" show={this.state.errorMessage.alertSuccess.active} onClose={() => this.closeAlert("success")} dismissible>
                        {this.state.errorMessage.alertSuccess.sms}
                    </Alert>
                    <Card style={{  fontSize: 14 }}>
                        <Card.Body>
                            <form>
                                <Row style={{textAlign:"left"}}>
                                    <Form.Group as={Col} xs={6} md={6} controlId="product.part1" style={{fontSize:24,fontWeight:"bold"}}>
                                        <Form.Label>Code utilisateur</Form.Label>
                                        <Form.Control style={{color:"green"}} plaintext readOnly defaultValue={this.state.codeUser} />
                                    </Form.Group>
                                </Row>
                                <Row style={{textAlign:"left"}}>
                                    <Form.Group as={Col} xs={6} md={6} controlId="pers.part1">
                                        <Form.Label>Date début</Form.Label>
                                        <Form.Control  required type="date"  name="date1" value={this.state.date1} onChange={this.handleChange} placeholder="start date" />
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} md={6} controlId="pers.part2">
                                        <Form.Label>Date fin</Form.Label>
                                        <Form.Control  required type="date"  name="date2" value={this.state.date2} onChange={this.handleChange} placeholder="end date" />
                                    </Form.Group>
                                </Row>
                                <br/>
                                <Row>
                                    <Col>
                                        <Button type="Subbmit" onClick={this.onValideRefresh} variant="success">Valider</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                    <br/>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={4} md={4} className="justify-content-md-right" >
                                    
                                </Col>

                                <Col xs={12} md={4} >
                                    <Form.Control  required type="text"  name="filter"  value={this.state.filter} onChange={this.onFilter} placeholder="ajouter un filtre... " />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                        {
                            this.state.filterResolt.map((item, key) =>
                            <Accordion key={key} style={{fontSize: 18}}>
                                <Accordion.Toggle as={Card.Header} eventKey={key} style={{  textAlign: "left"}}>
                                    <Row style={{textAlign: "left",fontSize:18}} >
                                        <Col xs={12} md={3} >
                                            N&#xb0; reference: <Badge style={{color:"#FFA500"}} >{item.numLettreTransport}</Badge>
                                        </Col>
                                        <Col >
                                            Client: <Badge style={{color:"#FFA500"}} >{item.client!=null?item.client.raisonSociale:""} </Badge>
                                        </Col>
                                        <Col >
                                            Date creation: 
                                            <Badge style={{fontWeight:"bold",fontSize:18}} >
                                                <Moment format="DD/MM/YYYY à HH:mm">
                                                    {item.createdAt}
                                                </Moment> 
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={key}>
                                    <Card.Body >
                                        <Row style={{textAlign: "left"}}>
                                            <Col xs={12} md={4}>
                                                Client: <Badge style={{color:"#FFA500", fontSize:18}} >{item.client!=null?item.client.raisonSociale:""} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Ville expédition: <Badge style={{color:"#FFA500", fontSize:18}} >{item.villeExpedition.nom} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Ville destination: <Badge style={{color:"#FFA500", fontSize:18}} >{item.villeDestination.nom} </Badge>
                                            </Col>
                                        </Row> 
                                        <Row style={{textAlign: "left"}}>
                                            <Col x={12} md={4}>
                                                Nature Colis: <Badge style={{color:"#FFA500", fontSize:18}} >{item.natureColis} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Bureau expédition: <Badge style={{color:"#FFA500", fontSize:18}} >{item.agenceExpedition.nom} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Bureau destination: <Badge style={{color:"#FFA500", fontSize:18}} >{item.agenceDestination.nom} </Badge>
                                            </Col>
                                        </Row> 
                                        <Row style={{textAlign: "left"}}>
                                            <Col x={12} md={4}>
                                                Nom Expediteur: <Badge style={{color:"#FFA500", fontSize:18}} >{item.contactExpediteur}</Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Nom destinataire: <Badge style={{color:"#FFA500", fontSize:18}} >{item.contactDestinataire} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Montant: <Badge style={{color:"#FFA500", fontSize:18}} >{item.montant} Fcfa</Badge>
                                            </Col>
                                        </Row>
                                        <Row style={{textAlign: "left"}}>
                                            <Col x={12} md={4}>
                                                Description: <Badge style={{color:"#FFA500", fontSize:18}} >{item.colis[0].description}</Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Tel expéditeur: <Badge style={{color:"#FFA500", fontSize:18}} >{item.telExpediteur} </Badge>
                                            </Col>
                                            <Col x={12} md={4}>
                                                Tel destinataire: <Badge style={{color:"#FFA500", fontSize:18}} >{item.telDestinataire} </Badge>
                                            </Col>
                                        </Row>
                                        
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Accordion>
                        )}
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        );
    }

}

export default ListLivraisons;