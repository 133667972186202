import React, { Component } from "react";
import { Route} from "react-router-dom";
import NavBar from "./component/NavBar";
import "./myStyle/myStyle.scss"


class CoursierComponent extends React.Component{
    constructor(props){
        super(props);
        this.createRef = this.createRef.bind(this);
    }

    createRef(element) {
        console.log(element);
    }

    render() {
        return (
            <div key="dashboard">
                <NavBar/>
                <div className="Content" ref={this.createRef}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const Coursier = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <CoursierComponent {...matchProps}>
                <Component {...matchProps} />
            </CoursierComponent>
        )} />
    )
};


export default Coursier