import Axios from "axios";
import {API_URL_ONLINE} from "../config";

class EnlevementAPI{

    getAllExpedition(codeUser,startdate,enddate){
        return Axios.get(`${API_URL_ONLINE}/api/expeditions?code_coursier=`+codeUser+`&date_debut=`+startdate+`&date_fin=`+enddate);
    }

    getAllTown(){
        return Axios.get(`${API_URL_ONLINE}/api/villes`);
    }

    getAllAgence(){
        return Axios.get(`${API_URL_ONLINE}/api/agences`);
    }

    postEnlevement(data){

        return Axios.post(`${API_URL_ONLINE}/api/expeditions/create`,data);
    }

    getClientProto(){
        return [
            {"id":1,
            "raison_sociale":"RDPC"},
            {"id":2,
            "raison_sociale":"MRC"},
            {"id":3,
            "raison_sociale":"TOTOPC"},
        ]
    }

    getClient(){
        return Axios.get(`${API_URL_ONLINE}/api/clients`);
    }

    getTypeColis(){
        return Axios.get(`${API_URL_ONLINE}/api/nature-colis`);
    }

    

    getTypeColisProto(){
        return [
            {"id":1,"type":"colis"},
            {"id":2,"type":"paquet"},
            {"id":3,"type":"fillet"}
        ]
    }

    filterAgencePerRegion(listeAgence,townId){
        var resolt = [];
        (listeAgence).forEach(agence => {
            if(agence.ville.id === townId)resolt.push(agence);
        });
        return resolt;
    }

    


}

export default new EnlevementAPI()