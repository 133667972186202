import React,{Component} from "react";
import {isMobile} from "react-device-detect";
import { Row,Col,Form, Card, Accordion, Container,Button, Badge, Alert,Table } from "react-bootstrap";
import TrackingAPI from "../service/TrackingAPI";
import Moment from 'react-moment';
import AuthService from '../service/authService';


class Tracking extends Component{

    constructor(prop){
        super(prop)
        this.state = {
            resolt: [],
            numero:"",//200101-YDA-006 , 200101-YDA-005  
            errorMessage: "",
            showError:false,
            showRT:true,
            showBE:true,
            //be: YDA012000011
            choice: "",
        }
        this.refreshTracking = this.refreshTracking.bind(this);
        this.onTracking = this.onTracking.bind(this);
        this.handleChange = this.handleChange.bind(this);  
        this.setStatusColor = this.setStatusColor.bind(this); 
        this.formChoice = this.formChoice.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    
    onTracking(event){
        this.refreshTracking();
        event.preventDefault();
    }

    formChoice(choice){
        if(choice === "LT")
        this.setState({
            choice: choice,
            showRT:false,
            showBE:true,
            numero:""
        })
        if(choice === "MANIFESTE")
        this.setState({
            choice: choice,
            showRT:true,
            showBE:false,
            numero:""
        })
    }

    refreshTracking(){
        this.setState({
            showError: false
        });
        if(this.state.numero === ""){
            this.setState({
                errorMessage: "Merci d'entrer un numero de "+this.state.choice,
                showError: true
            });
        }else{
            TrackingAPI.getTracking(this.state.choice, this.state.numero).then(
                resp => {

                    if(resp.data.code === 401){
                        AuthService.onLogout();
                    }else{
                        //console.log("---------- data ----------");
                        //console.log(resp.data.data.data_array);
                        if(resp.data.status === "success")this.setState({resolt:resp.data.data.data_array})
                        if(resp.data.data.lt_errors.length !== 0){
                            var liste_lt_error = "";
                            resp.data.data.lt_errors.forEach(element => {
                                liste_lt_error = liste_lt_error +" "+ element;
                            });
                            this.setState({
                                errorMessage: "Certain RT sont incorrects: \""+liste_lt_error+ "\"",
                                showError: true
                            });
                        }
                    }
                    
                }
            ).catch((error) => { 
                var final = error+"-";
                if(final.includes("401")){
                    AuthService.onLogout();
                    //this.props.history.push("/login");
                }
                this.setState({
                    errorMessage: "Ce numéro de BE est incorrect ",
                    showError: true
                });
            });
        }
         
    }

    closeAlert(){
        this.setState({
            showError: false
        })
    }

    handleChange (event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    setStatusColor(code){
        var codeColor=[];
        codeColor["LIVRE"] = "#0ECB01";
        codeColor["ENREGISTRE"] = "#CEE3F6";
        codeColor["ENVOYE"] = "#81DAF5";
        codeColor["TRANSIT_ENTRANT"] = "#F3F781";
        codeColor["TRANSIT_SORTANT"] = "#F7A517";
        codeColor["RECEPTIONNE"] = "#F9ABAB";
        codeColor["RETOUR"] = "#F43900";
        codeColor["LIVRAISON_EN_COURS"] = "#82FA58";
        codeColor["MAGASIN"] = "#FE2E2E";
        codeColor["TRANSMIS_TNT"] = "#0ECB01";
        codeColor["ANNULE"] = "#FFE4C4";

        return codeColor[code];
    }


    render(){

        const styles = {
            styleMobile: {
                marginLeft: 1,
                marginRigth: 1
            },
            styleDesktop: {
                marginLeft: 400,
                marginRight: 400
            }

        }
        return(
            <div className="Tracking" >
                <Container fluid="xs" style={isMobile?styles.styleMobile:styles.styleDesktop}>
                <Alert key="1002" variant="danger" show={this.state.showError} dismissible onClose={() => this.closeAlert()}>
                    {this.state.errorMessage}
                </Alert>
                    <Card style={{  fontSize: 14 }}>
                        <Card.Body>
                            <form>
                                <Row>
                                    <Col hidden={this.state.showRT}>
                                        <Form.Control  required  name="numero" value={this.state.lt} onChange={this.handleChange} placeholder="exp: RT,RT" />
                                    </Col>
                                    <Col hidden={this.state.showBE}>
                                        <Form.Control required name="numero" value={this.state.be} onChange={this.handleChange} placeholder="numéro BE" />
                                    </Col>
                                </Row>  
                                <br/>  
                                <fieldset> 
                                    <Form.Group as={Row}>
                                        <Col >
                                            <Form.Check
                                            type="radio"
                                            label="N° Reçu Transport (RT)"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios1"
                                            onClick={()=>this.formChoice("LT")}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                            type="radio"
                                            label="N° Bordereau d'Expédition (BE)"
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios2"
                                            onClick={()=>this.formChoice("MANIFESTE")}
                                            />
                                        </Col>
                                    </Form.Group>
                                </fieldset>
                                <Row>
                                    <Col>
                                        <Button type="Subbmit" onClick={this.onTracking} >Afficher</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Card.Body>
                    </Card>
                    {
                        this.state.resolt.map((tracking, key) =>
                        <Accordion key={key} style={{fontSize: 18}}>
                        {
                            tracking.colis.map((item, key) =>
                            <Card key={key} >
                                <Accordion.Toggle as={Card.Header} eventKey={key} style={{  textAlign: "left"}}>
                                    <Row style={{textAlign: "left",fontSize:18}} >
                                        <Col xs={12} md={3} >
                                        N&#xb0; reference: <Badge style={{color:"#FFA500"}} >{item.numReference} </Badge>
                                        </Col>
                                        <Col md={3} className="d-none d-lg-block" >
                                        Lettre transport: <Badge style={{color:"#FFA500"}} >{tracking.numLettreTransport} </Badge>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            Status: <Badge style={{backgroundColor:this.setStatusColor(item.status.code)}} >{item.status.libelle}</Badge>
                                        </Col>
                                        <Col md={2} hidden={isMobile} className="d-none d-lg-block">
                                            Date: <Badge style={{fontWeight:"bold"}} ><Moment format="DD/MM/YYYY à HH:mm">
                                                            {tracking.createdAt}
                                                        </Moment> 
                                                  </Badge>
                                        </Col>
                                        <Col md={2} className="d-none d-lg-block">
                                            Lieu: <Badge style={{fontWeight:"bold"}} >{tracking.villeExpedition.nom}/{tracking.agenceExpedition.nom}</Badge>
                                        </Col>
                                    </Row> 
                                    
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={key}>
                                    <Card.Body >
                                        <Row style={{textAlign: "left"}}>
                                            <Col >
                                            N&#xb0; de reference: <Badge style={{color:"#FFA500", fontSize:22}} >{item.numReference} </Badge>
                                            </Col>
                                            <Col >
                                            Lettre transport: <Badge style={{color:"#FFA500", fontSize:22}} >{tracking.numLettreTransport} </Badge>
                                            </Col>
                                        </Row> 
                                        <Row style={{textAlign: "left"}}>
                                            <Col >
                                                Ramassage: 
                                                <Badge style={{fontWeight:"bold",fontSize:22}} >
                                                    <Moment format="DD/MM/YYYY à HH:mm">
                                                        {item.updatedAt}
                                                    </Moment> 
                                                </Badge>
                                            </Col>
                                            <Col >
                                            Destination: <Badge style={{fontWeight:"bold",fontSize:20}} >{tracking.paysDestination.nomFr} / {tracking.villeDestination.nom}</Badge>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row style={{textAlign: "left",backgroundColor: "rgba(0,0,0,.03)",paddingTop:10, paddingBottom:10, fontSize:16}}>
                                            <Col xs={12} md={4}>
                                                Statut actuel:<Badge style={{backgroundColor:this.setStatusColor(item.status.code),fontSize:16}} >{item.status.libelle}</Badge>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                Date: <Badge style={{fontWeight:"bold",fontSize:16}} >
                                                        <Moment format="DD/MM/YYYY à HH:mm">
                                                        {item.updatedAt}
                                                        </Moment></Badge>
                                            </Col>
                                            <Col xs={12} md={4} >
                                                Lieu: <Badge style={{fontWeight:"bold",fontSize: !isMobile?16:12}} >{tracking.villeDestination.nom} /  {tracking.agenceDestination.nom} </Badge>
                                            </Col>
                                        </Row>
                                        <br/>  
                                        <Row>
                                            <Col style={{fontWeight:"bold",fontSize:18,color:"#77b5fe"}}>
                                                Les étapes de l'expédition de votre colis
                                            </Col>
                                        </Row>  
                                        <Table style={{textAlign: "left"}} striped bordered hover responsive="xl">
                                        <tbody>
                                            
                                            {
                                                item.trackings.map((etat, key) =>
                                                <tr key={key}>
                                                    <td> {etat.newStatus.label}</td>
                                                    <td>le&nbsp;
                                                        <Moment format="DD/MM/YYYY à HH:mm">
                                                            {etat.dateTracking}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        {etat.agence.nom}
                                                    </td>
                                                    
                                                </tr>
                                                
                                            )}
                                            <tr>
                                                <td> Enrégistré</td>
                                                <td>le&nbsp;
                                                    <Moment format="DD/MM/YYYY à HH:mm">
                                                        {tracking.createdAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {tracking.agenceExpedition.nom}
                                                </td>
                                            </tr>
                                        </tbody>

                                        </Table>
                                        
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )}
                        </Accordion>  
                    )}      
                </Container>
            </div>
        );
    }
}

export default Tracking