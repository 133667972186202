import React, { Component } from "react";
import { Route } from "react-router-dom";
import "./myStyle/myStyle.scss"

const CoursierNoMenu = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <div style={{marginTop:40}}>
                <Component {...matchProps} />
            </div>
        )} />
    )
};


export default CoursierNoMenu