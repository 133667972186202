import Axios from "axios";
import {API_URL_ONLINE} from "../config";

class TrackingAPI{


    getTracking(choice,numero){
        var type ="";
        choice==="MANIFESTE"? type = "bordereau": type = "lt";

        return Axios.get(`${API_URL_ONLINE}/api/trackings?tracking_type=`+choice+`&numero_`+type+`=`+numero)
        
    }

    

}

export default new TrackingAPI()