import React,{Component} from 'react';
import { Link, withRouter } from "react-router-dom";
import {Navbar,Nav,NavDropdown } from 'react-bootstrap';
import {isMobile} from "react-device-detect";
import "../myStyle/mainStyle.css";
import AuthService from '../service/authService';
import Logo from "../logo.jpeg";
import {iconsvg} from "@fortawesome/fontawesome-svg-core";

class NavBar extends Component{

    constructor(props){
        super(props)
        this.onSelectItem = this.onSelectItem.bind(this);
        this.onMouseInterItem = this.onMouseInterItem.bind(this);
        this.onLogout = this.onLogout.bind(this);

        this.state = {
            onSelectItem: false,
            usrName: "",
            one:1,
            item1:"#32CD32",
            item2:"green",
            item3:"green",
            item4:"green",
            item5:"green"
        }
    }
    onSelectItem(){
        if(isMobile)
        this.setState({
            onSelectItem: !this.state.onSelectItem
        });
    }
    onMouseInterItem(item){
        this.setState({
            item1 : "green",
            item2 : "green",
            item3 : "green",
            item4 : "green",
            item5 : "white",
        });
        this.setState({
            [item]: "#32CD32"
        })
    }

    onLogout(){
        this.onSelectItem();
        AuthService.onLogout();
    }
    componentDidMount(){
        this.setState({
            usrName: sessionStorage.getItem('username')
        })
    }

    render(){
        return (
            <header>
                <Navbar expanded={this.state.onSelectItem} expand="lg" style={{backgroundColor:"green",marginBottom:10}}>
                    <Navbar.Brand href="#home" style={{color:"white"}} style={{
                                        boxShadow: "0px 10px 14px -7px gray",
                                        
                                        
                                        borderRadius:"4px",
                                        border:"1px solid gray",
                                        display:"inline-block",
                                        cursor:"pointer",
                                        color:"white",
                                        fontFamily:"Arial",
                                        fontSize:"18px",
                                        fontWeight:"bold",
                                        padding:"7px",
                                        textDecoration:"none",
                                        textShadow:"0px 1px 0px #5b8a3c"
                                    }}>Danay Express{/*<img src={Logo} alt="website logo" width="100"/>*/}</Navbar.Brand>
                    <Navbar.Toggle onClick={this.onSelectItem} aria-controls="basic-navbar-nav" color="red" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Link onClick={this.onSelectItem} onMouseOver={()=>this.onMouseInterItem("item1")} className="nav-link" to="/saisi" style={{backgroundColor:this.state.item1,color:"white"}}>SAISI ENLEVEMENT</Link>
                            <Link onClick={this.onSelectItem} onMouseOver={()=>this.onMouseInterItem("item2")} className="nav-link" to="/tracking" style={{backgroundColor:this.state.item2,color:"white"}}>TRACKING</Link>
                            <Link onClick={this.onSelectItem} onMouseOver={()=>this.onMouseInterItem("item3")} className="nav-link" to="/elevements" style={{backgroundColor:this.state.item3,color:"white"}}>LISTE DES ENLEVEMENTS</Link>
                            {/*<NavDropdown title="OPERATION" id="collasible-nav-dropdown" style={{color:"white"}}>
                                <NavDropdown.Divider />
                                <Link onClick={this.onSelectItem} onMouseOver={()=>this.onMouseInterItem("item4")} className="nav-link" to="/elevements" style={{backgroundColor:this.state.item4,color:"white"}}>Liste des Enlevements</Link>
                            </NavDropdown>
                            */}
                        </Nav>
                       
                        <Nav> 
                            <NavDropdown title="" id="collasible-nav-dropdown" bsPrefix="myNavDropdown">
                                <Link onClick={this.onLogout} onMouseOver={()=>this.onMouseInterItem("item5")} className="nav-link" to="/logout" style={{color:"gray"}}>Logout</Link>
                            </NavDropdown>
                        </Nav>
                        <Nav style={{color:"white"}}>
                            <Link onClick={this.onSelectItem}  className="nav-link" to="#" style={{color:"white"}}>{this.state.usrName}</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                
            </header>
        );
    }
}

export default withRouter(NavBar)